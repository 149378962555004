@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  object-fit: fill;
}
.Toastify__toast {
  width: 400px;
}
.Sidebar-Link:hover span {
  color: #c0c0c0;
}

.google-auth-btn > button {
  width: 100%;
  justify-content: center;
  background: #ffffff !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  padding: 8px 0 !important;
}

button {
  text-transform: none !important;
}
